import { memo, Suspense } from 'react';

import { Outlet } from 'react-router-dom';
import { Container, FoundationBlueberryBlueberry1 } from '@djob/react-components';
import { Col, ConfigProvider, Layout, Row, theme } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import Store from './DefaultLayout.store';
import Spinner from 'components/Spinner';

export const AntLayout = memo(() => {
  const { useToken } = theme;
  const { sizeLG, sizeMS } = useToken().token;
  const { settings } = Store.useStore((state) => state);
  const { RightSideBarContent, hasRightSideBarContent } = settings;
FoundationBlueberryBlueberry1
  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerPadding: 0,
            footerPadding: 0,
            headerHeight: 'auto',
          },
        },
      }}
    >
      <Layout>
        <Header>
          <Container style={{ border: '1px solid blue' }}>Header</Container>
        </Header>
        <Content>
          <Container style={{ border: '1px solid green' }}>
            <Row gutter={[sizeLG, sizeLG]}>
              {/*Left Col */}
              <Col lg={{ span: hasRightSideBarContent ? sizeMS : sizeLG }} flex="auto">
                <Suspense fallback={<Spinner />}>
                  <Outlet />
                </Suspense>
              </Col>
              {/*Right Col */}
              {hasRightSideBarContent && (
                <Col lg={{ span: 8 }} md={{ span: 0 }} sm={{ span: 0 }} xs={{ span: 0 }}>
                  <RightSideBarContent />
                </Col>
              )}
            </Row>
          </Container>
        </Content>
        <Footer>
          <Container style={{ border: '1px solid pink' }}>Footer</Container>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
});

AntLayout.displayName = 'Layouts_AntLayout';
